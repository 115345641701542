var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Travel Plans")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('b-container', [_c('b-row', [_c('b-col', [_c('SecondmentTravelPlanner', {
    attrs: {
      "from-wizard": false,
      "travel-plans": _vm.travelPlans,
      "secondment-employees": _vm.secondmentEmployees,
      "country": _vm.country,
      "client-address-id": _vm.clientAddressId,
      "client": _vm.client,
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }