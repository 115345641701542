<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Travel Plans") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <b-container>
        <b-row>
          <b-col>
            <SecondmentTravelPlanner :from-wizard="false" :travel-plans="travelPlans"
              :secondment-employees="secondmentEmployees" :country="country" :client-address-id="clientAddressId"
              :client="client" disabled></SecondmentTravelPlanner>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SecondmentTravelPlanner from "@/view/components/secondment-travel-planner";
export default {
  components: {
    SecondmentTravelPlanner,
  },
  props: {
    travelPlans: {
      type: Array,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    clientAddressId: {
      type: Number,
      required: true,
    },
    client: {
      type: Object,
      default: () => { }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments")},
      { title: this.$t("Travel Plans") },
    ]);
  }
};
</script>
